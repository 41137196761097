<template>
  <form @submit.prevent="salvar">
    <div class="p-grid card">
      <div class="p-col-12">
        <h5>Senha de Acesso</h5>
      </div>
      <div class="p-col-12 p-md-6 p-md-offset-3">
        <div class="p-fluid">
          <div class="p-field">
            <label>E-mail</label>
            <p-inputtext
              :value="$store.state.user?.email"
              required
              autocomplete="username"
              disabled
            />
          </div>
          <div class="p-field">
            <label>Senha atual</label>
            <input
              type="hidden"
              :value="$store.state.user?.email"
              autocomplete="username"
            />
            <p-inputtext
              type="password"
              v-model="input.password"
              required
              autocomplete="current-password"
            />
          </div>
          <div class="p-field">
            <label>Nova senha</label>
            <p-inputtext
              type="password"
              v-model="input.newPassword"
              required
              autocomplete="new-password"
            />
          </div>
          <div class="p-field">
            <label>Nova senha</label>
            <p-inputtext
              type="password"
              v-model="input.newPasswordConfirmation"
              required
              autocomplete="new-password"
            />
          </div>
        </div>
      </div>
      <div
        class="p-d-flex p-flex-row-reverse p-col-12 p-md-6 p-md-offset-3"
        style="justify-content: end"
      >
        <p-button
          :icon="saving ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
          label="Salvar"
          type="submit"
          :disabled="saving"
        />
        <p-button
          class="p-button-secondary p-mr-2"
          label="Cancelar"
          @click="$router.push({ name: 'dashboard' })"
        />
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { UserChangePassword, getService } from "@/services/UserService";
import { computed, defineComponent, onMounted, ref } from "vue";
import Avatar from "@/components/Avatar.vue";

export default defineComponent({
  components: {
    Avatar,
  },
  setup() {
    const saving = ref(false);

    const input = ref<UserChangePassword>({
      password: "",
      newPassword: "",
      newPasswordConfirmation: "",
    });

    return {
      saving,
      input,
    };
  },
  methods: {
    async salvar() {
      if (this.input.newPassword !== this.input.newPasswordConfirmation) {
        this.$toast.add({
          severity: "warn",
          summary: "A confirmação de senha não está correta",
          life: 3000,
        });
        return;
      }
      this.saving = true;
      try {
        await getService().changePassword(this.input);
        this.$toast.add({
          severity: "success",
          detail: "Senha alterada com sucesso",
          life: 3000,
        });
        this.$router.push({ name: "dashboard" });
      } finally {
        this.saving = false;
      }
    },
  },
});
</script>
