
import { UserChangePassword, getService } from "@/services/UserService";
import { computed, defineComponent, onMounted, ref } from "vue";
import Avatar from "@/components/Avatar.vue";

export default defineComponent({
  components: {
    Avatar,
  },
  setup() {
    const saving = ref(false);

    const input = ref<UserChangePassword>({
      password: "",
      newPassword: "",
      newPasswordConfirmation: "",
    });

    return {
      saving,
      input,
    };
  },
  methods: {
    async salvar() {
      if (this.input.newPassword !== this.input.newPasswordConfirmation) {
        this.$toast.add({
          severity: "warn",
          summary: "A confirmação de senha não está correta",
          life: 3000,
        });
        return;
      }
      this.saving = true;
      try {
        await getService().changePassword(this.input);
        this.$toast.add({
          severity: "success",
          detail: "Senha alterada com sucesso",
          life: 3000,
        });
        this.$router.push({ name: "dashboard" });
      } finally {
        this.saving = false;
      }
    },
  },
});
